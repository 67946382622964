export default [
  {
    path: '/users/message',
    name: 'message',
    component: () => import('@/views/users/message/Message.vue'),
  },
  {
    path: '/users/user',
    name: 'user',
    component: () => import('@/views/users/user/User.vue'),
  },
]
