import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class Reports extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchReport(params) {
    return this.get('/admin/report/report', { params })
  }

  fetchOneReport(id, params) {
    return this.get(`/admin/report/report/${id}`, { params })
  }

  createReport(body) {
    return this.post('/admin/report/report', body)
  }

  deleteReport(id) {
    return this.delete(`/admin/report/report/${id}`)
  }

  updateReport(id, body) {
    return this.post(`/admin/report/report/${id}?_method=PATCH`, body)
  }

  deleteReportFile(id, index) {
    return this.delete(`/admin/report/report/${id}/delete-file/images_list/${index}`)
  }

}

export default Reports

