import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class Regions extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchCountry(params) {
    return this.get('/admin/region/country', { params })
  }

  fetchOneCountry(id) {
    return this.get(`/admin/region/country/${id}`)
  }

  createCountry(body) {
    return this.post('/admin/region/country', body)
  }

  deleteCountry(id) {
    return this.delete(`/admin/region/country/${id}`)
  }

  updateCountry(id, body) {
    return this.put(`/admin/region/country/${id}`, body)
  }

  fetchCity(params) {
    return this.get('/admin/region/city', { params })
  }

  fetchOneCity(id) {
    return this.get(`/admin/region/city/${id}`)
  }

  createCity(body) {
    return this.post('/admin/region/city', body)
  }

  deleteCity(id) {
    return this.delete(`/admin/region/city/${id}`)
  }

  updateCity(id, body) {
    return this.put(`/admin/region/city/${id}`, body)
  }

  fetchRegion(params) {
    return this.get('admin/region/region', { params })
  }

  fetchOneRegion(id) {
    return this.get(`/admin/region/region/${id}`)
  }

  createRegion(body) {
    return this.post('/admin/region/region', body)
  }

  deleteRegion(id) {
    return this.delete(`/admin/region/region/${id}`)
  }

  updateRegion(id, body) {
    return this.put(`/admin/region/region/${id}`, body)
  }

}

export default Regions

