export default [
  {
    path: '/products/product',
    name: 'product',
    component: () => import('@/views/products/product/Product.vue'),
  },

  {
    path: '/products/category',
    name: 'category',
    component: () => import('@/views/products/category/Category.vue'),
  },

  {
    path: '/products/brand',
    name: 'brand',
    component: () => import('@/views/products/brand/Brand.vue'),
  },

  {
    path: '/products/specification',
    name: 'specification',
    component: () => import('@/views/products/specification/Specification.vue'),
  },

  {
    path: '/products/:id/variation',
    name: 'variation',
    component: () => import('@/views/products/variation/Variation.vue'),
  },
]
