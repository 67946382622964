export default [
  {
    path: '/regions/country',
    name: 'country',
    component: () => import('@/views/regions/country/Country.vue'),
  },
  {
    path: '/regions/city',
    name: 'city',
    component: () => import('@/views/regions/city/City.vue'),
  },
  {
    path: '/regions/region',
    name: 'region',
    component: () => import('@/views/regions/region/Region.vue'),
  },
]
