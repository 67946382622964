import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class Auth extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  signIn(login) {
    return this.post('/auth/login', login)
  }

  // updateToken(refreshToken) {
  //   return this.post('auth/refreshToken', { refreshToken })
  // }
}

export default Auth

