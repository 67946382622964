import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Products extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  // category

  fetchSpecId(id, params) {
    return this.get(`/admin/product/specification/${id}`, { params })
  }

  fetchCategory(params) {
    return this.get('/admin/product/category', { params })
  }

  fetchOneCategory(id, params) {
    return this.get(`/admin/product/category/${id}`, { params })
  }

  createCategory(body) {
    return this.post('/admin/product/category', body)
  }

  deleteCategory(id) {
    return this.delete(`/admin/product/category/${id}`)
  }

  updateCategory(id, body) {
    return this.put(`/admin/product/category/${id}`, body)
  }

  // brand
  fetchBrand(params) {
    return this.get('/admin/product/brand', { params })
  }

  fetchOneBrand(id) {
    return this.get(`/admin/product/brand/${id}`)
  }

  createBrand(body) {
    return this.post('/admin/product/brand', body)
  }

  deleteBrand(id) {
    return this.delete(`/admin/product/brand/${id}`)
  }

  updateBrand(id, body) {
    return this.put(`/admin/product/brand/${id}`, body)
  }

  updateBrandActive(id, active) {
    return this.post(`/admin/product/brand/${id}/set-active/${active}`)
  }

  // product
  fetchProduct(params) {
    return this.get('/admin/product/product', { params })
  }

  fetchOneProduct(id, params) {
    return this.get(`/admin/product/product/${id}`, { params })
  }

  createProduct(body) {
    return this.post('/admin/product/product', body)
  }

  deleteProduct(id) {
    return this.delete(`/admin/product/product/${id}`)
  }

  updateProduct(id, body) {
    return this.post(`/admin/product/product/${id}?_method=PATCH`, body)
  }

  deleteProductFile(id, index) {
    return this.delete(`/admin/product/product/${id}/delete-file/images_list/${index}`)
  }

  updateProductActive(id, active) {
    return this.post(`/admin/product/product/${id}/set-active/${active}`)
  }

  // specification
  fetchSpec(params) {
    return this.get('/admin/product/specification', { params })
  }

  fetchOneSpec(id, params) {
    return this.get(`/admin/product/specification/${id}`, { params })
  }

  createSpec(body) {
    return this.post('/admin/product/specification', body)
  }

  deleteSpec(id) {
    return this.delete(`/admin/product/specification/${id}`)
  }

  updateSpec(id, body) {
    return this.put(`/admin/product/specification/${id}`, body)
  }

  // variation
  fetchVariation(params) {
    return this.get(`/admin/product/variation`, { params })
  }

  fetchOneVariation(id, params) {
    return this.get(`/admin/product/variation/${id}`, { params })
  }

  createVariation(body) {
    return this.post('/admin/product/variation', body)
  }

  deleteVariation(id) {
    return this.delete(`/admin/product/variation/${id}`)
  }

  updateVariation(id, body) {
    return this.post(`/admin/product/variation/${id}?_method=PATCH`, body)
  }

  deleteVariationFile(id, index) {
    return this.delete(`/admin/product/variation/${id}/delete-file/images_list/${index}`)
  }

}

export default Products

