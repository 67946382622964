import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Users extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchUser(params) {
    return this.get('/admin/user/user', { params })
  }

  fetchOneUser(id, params) {
    return this.get(`/admin/user/user/${id}`, { params })
  }

  fetchUserByRole(id, params) {
    return this.get(`/admin/user/user/${id}`, { params })
  }

  createUser(body) {
    return this.post('/admin/user/user', body)
  }

  deleteUser(id) {
    return this.delete(`/admin/user/user/${id}`)
  }

  updateUser(id, body) {
    return this.put(`/admin/user/user/${id}?_method=PATCH`, body)
  }

}

export default Users

