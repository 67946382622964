import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class System extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchSystemInfo(params) {
    return this.get('/admin/system/settings', { params })
  }

  updateSystemInfo(body) {
    return this.post('/admin/system/settings', body)
  }

}

export default System

