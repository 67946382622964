import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class Tasks extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchTask(params) {
    return this.get('/admin/task/task', { params })
  }

  fetchOneTask(id, params) {
    return this.get(`/admin/task/task/${id}`, { params })
  }

  createTask(params) {
    return this.post('/admin/task/task', params)
  }

  deleteTask(id) {
    return this.delete(`/admin/task/task/${id}`)
  }

  updateTask(id, body) {
    return this.post(`/admin/task/task/${id}?_method=PATCH`, body)
  }

  deleteTaskFile(id, index) {
    return this.delete(`/admin/task/task/${id}/delete-file/images_list/${index}`)
  }

  changeAdminStatus(id, status) {
    return this.post(`admin/task/task/${id}/set-admin_status/${status}`)
  }

}

export default Tasks

