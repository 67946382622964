import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Shops extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  // COMPANIES API
  fetchCompany( params ) {
    return this.get('/admin/shop/company', { params })
  }

  fetchOneCompany(id) {
    return this.get(`/admin/shop/company/${id}`)
  }

  createCompany(body) {
    return this.post('/admin/shop/company', body)
  }

  deleteCompany(id) {
    return this.delete(`/admin/shop/company/${id}`)
  }

  updateCompany(id, body) {
    return this.put(`/admin/shop/company/${id}`, body)
  }


  // CONTACTS API
  fetchContact( params ) {
    return this.get('/admin/shop/contact', { params })
  }

  fetchOneContact( id, params ) {
    return this.get(`/admin/shop/contact/${id}`, { params })
  }

  createContact(body) {
    return this.post('/admin/shop/contact', body)
  }

  deleteContact(id) {
    return this.delete(`/admin/shop/contact/${id}`)
  }

  updateContact(id, body) {
    return this.put(`/admin/shop/contact/${id}`, body)
  }

  updateContactActive(id, active) {
    return this.post(`/admin/shop/contact/${id}/set-active/${active}`)
  }

  // SUPPLIER API
  fetchSupplier( params ) {
    return this.get('/admin/shop/supplier', { params })
  }

  fetchOneSupplier( id, params ) {
    return this.get(`/admin/shop/supplier/${id}`, { params })
  }

  createSupplier(body) {
    return this.post('/admin/shop/supplier', body)
  }

  deleteSupplier(id) {
    return this.delete(`/admin/shop/supplier/${id}`)
  }

  updateSupplier(id, body) {
    return this.put(`/admin/shop/supplier/${id}`, body)
  }

  updateSupplierActive(id, active) {
    return this.post(`/admin/shop/supplier/${id}/set-active/${active}`)
  }

  // SHOPS API
  fetchShop( params ) {
    return this.get('/admin/shop/shop', { params })
  }

  fetchOneShop( id, params ) {
    return this.get(`/admin/shop/shop/${id}`, { params })
  }

  createShop(body) {
    return this.post('/admin/shop/shop', body)
  }

  deleteShop(id) {
    return this.delete(`/admin/shop/shop/${id}`)
  }

  updateShop(id, body) {
    return this.put(`/admin/shop/shop/${id}`, body)
  }

  updateShopActive(id, active) {
    return this.post(`/admin/shop/shop/${id}/set-active/${active}`)
  }

}

export default Shops

