export default [
  {
    path: '/shops/company',
    name: 'company',
    component: () => import('@/views/shops/company/Company.vue'),
  },
  {
    path: '/shops/contact',
    name: 'contact',
    component: () => import('@/views/shops/contact/Contact.vue'),
  },
  {
    path: '/shops/shop',
    name: 'shop',
    component: () => import('@/views/shops/shop/Shop.vue'),
  },
  {
    path: '/shops/supplier',
    name: 'supplier',
    component: () => import('@/views/shops/supplier/Supplier.vue'),
  },
]
