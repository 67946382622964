import Auth from "@/services/auth"
import Regions from "@/services/regions"
import Users from "@/services/users"
import Shops from "@/services/shops"
import Products from '@/services/products'
import Reports from '@/services/reports'
import Enums from '@/services/enums'
import Tasks from '@/services/tasks'
import System from '@/services/system'


export default {
    auth: new Auth(),
    regions: new Regions(),
    users: new Users(),
    shops: new Shops(),
    products: new Products(),
    reports: new Reports(),
    enums: new Enums(),
    tasks: new Tasks(),
    system: new System()
}
