import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class Enums extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchReportTypes(params) {
    return this.get('/information/enums/report', { params })
  }

  fetchTaskTypes(params) {
    return this.get('/information/enums/task', { params })
  }

  fetchUserRole(params) {
    return this.get('/information/enums/user', { params })
  }

  fetchAdminStatus(params) {
    return this.get('/information/enums/task', { params })
  }

}

export default Enums

